import React, { useEffect, useState } from 'react';
import './index.css';
import { trustPilotScriptTimeout } from '../../configs';

const TrustpilotBlock = () => {
  const [trustpilotBgShow, setTrustpilotBgShow] = useState(true);

  useEffect(() => {
    const TrustpilotBgShowTimeout = setTimeout(() => {
      setTrustpilotBgShow(false);
    }, trustPilotScriptTimeout);
    return () => {
      clearTimeout(TrustpilotBgShowTimeout);
    };
  }, []);

  return (
    <div className="trustpilot-wrapper">
      <div className="hide-link" />
      <div
        className="trustpilot-widget trustpilot-block"
        data-locale="en-US"
        data-template-id="53aa8807dec7e10d38f59f32"
        data-businessunit-id="51c1ebb90000640005477b45"
        data-style-height="100px"
        data-style-width="170px"
        data-theme="light"
        data-stars="5"
      >
        {trustpilotBgShow && <div className="trustpilot-bg" />}
      </div>
    </div>
  );
};

export default TrustpilotBlock;
