import React from 'react';

import './SidebarStaticPage.css';
import Menu from '../Menu/Menu';
import Link from '../Link';
import { formatPhone } from '../../helpers/callTracking';
import TrustpilotBlock from '../TrustpilotBlock';
import { useWindowSize } from '../../helpers/useWindowSize';

const SidebarStaticPage = ({ phoneNumber, isCanada }) => {
  const eventsClickPhone = () => {
    sessionStorage.setItem('modalExitIntentPopup', true);
  };

  const [width] = useWindowSize();

  return (
    <div className="sidebar">
      <div className="holder">
        <div className="block">
          <h4>More Information</h4>
          <Menu isCanada={isCanada} />
        </div>

        <div className="block">
          <h4>Need help?</h4>
          <p>
            Our team of professional travel experts are ready to help 24/7. Call
            us toll free:
          </p>
          <Link
            number={formatPhone(phoneNumber, 'callAction')}
            className="call-us-phone d-flex"
            onClick={() => eventsClickPhone()}
          >
            <svg
              className="call-us-icon dial-icon sc-gZMcBi dIHaiX"
              width="22px"
              height="22px"
              viewBox="0 0 22 22"
            >
              <defs>
                <linearGradient
                  x1="50%"
                  y1="-2.48949813e-15%"
                  x2="50%"
                  y2="100%"
                  id="linearGradient-1"
                >
                  <stop stopColor="#FFB300" offset="0%"></stop>
                  <stop stopColor="#FF5100" offset="100%"></stop>
                </linearGradient>
              </defs>
              <g
                id="Web"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="style-guide"
                  transform="translate(-102.000000, -1229.000000)"
                  fill="url(#linearGradient-1)"
                  fillRule="nonzero"
                >
                  <path
                    d="M113,1251 C106.924868,1251 102,1246.07513 102,1240 C102,1233.92487 106.924868,1229 113,1229 C119.075132,1229 124,1233.92487 124,1240 C124,1246.07513 119.075132,1251 113,1251 Z M110.261841,1234.0035 C109.765788,1233.9668 109.231577,1234.22366 108.888156,1234.5539 C108.27763,1235.1777 107.934209,1236.13174 108.010524,1236.68215 C108.468419,1239.83783 112.81842,1244.31449 116.061842,1244.97498 C116.596052,1245.08506 117.626316,1244.8282 118.313158,1244.27779 C118.694737,1243.94755 118.961842,1243.47053 119,1242.99351 C119,1242.4064 116.596052,1240.79187 115.871052,1241.01203 C115.527631,1241.12212 115.374999,1241.59914 115.222368,1241.89269 C114.917105,1242.36971 114.535526,1242.25963 114.153946,1241.92938 C113.123683,1241.12212 111.788157,1239.76444 111.024999,1238.70032 C110.719735,1238.33338 110.605262,1237.92975 111.139472,1237.70958 C111.444735,1237.56281 111.940788,1237.45272 112.09342,1237.12248 C112.398683,1236.46199 110.872367,1234.0035 110.261841,1234.0035 Z"
                    id="call-us"
                  />
                </g>
              </g>
            </svg>
            <span className="call-us-text">{formatPhone(phoneNumber)}</span>
          </Link>
        </div>
        {width > 1024 && (
          <div className="block">
            <TrustpilotBlock />
          </div>
        )}
      </div>
    </div>
  );
};

export default SidebarStaticPage;
