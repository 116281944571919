import React from 'react';

import Layout from '../../components/layout';
import withBg from '../../helpers/withBg';
import SEO from '../../components/seo';
import TrustpilotHorizontal from '../../components/TrustpilotHorizontal/index';
import SidebarStaticPage from '../../components/Sidebar/SidebarStaticPage';

const data = {
  title: 'Our Culture',
  seoTitle: 'Our Culture | Travelopod',
  seoDescription:
    'Find and book the cheapest flights to any destination at Travelopod! Save money on unique deals for flights, hotels and car rentals online or call us 24/7 for live booking assistance.'
};

class OurCulturePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.phoneNumber
    };
  }

  render() {
    const { phoneNumber } = this.state;
    const description = data.seoDescription;
    const seotitle = data.seoTitle;

    return (
      <Layout
        isStaticPage
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
      >
        <SEO title={seotitle} description={description} />
        <div className="main c-content-page">
          <div className="page-content">
            <div className="flight-content">
              <div className="text-block">
                <h1>Our Culture</h1>
                <p>
                  Travelopod Team <br />
                  Working with Travelopod is not only a job, it’s a beautiful
                  journey and an experience to cherish. There is so much to
                  explore every day about yourself, your potential and your
                  goals. We strive hard to facilitate our people with a
                  challenging and an enjoyable working environment.
                </p>
                <p>
                  At Travelopod.com we understand that culture of a company
                  motivates its employees to put in their best and stay on the
                  top. Great leaders inspire and attract talented and high
                  performers.
                </p>
                <p>
                  <strong>We NUTURE our culture!</strong> The roots of our
                  culture lie in having fun, sharing, collaborating and
                  connecting with each and everyone.
                </p>
                <p>
                  At Travelopod.com we value our team on par with our customers.
                  We genuinely understand that its our people that make a
                  company and give our clients a reason to choose us over our
                  competitors.
                </p>
                <p>
                  We believe in investing in our employees to stay ahead in the
                  business. Professional trainings and workshops are standard
                  practice at our company.
                </p>
                <p>
                  Our culture is much more than just paid lunches or providing
                  personal parking spaces. We put in all our efforts to
                  understand our employees. We conduct regular meetings, one to
                  one talks with our employees to know and understand them
                  better. At Travelopod we encourage feedback from all employees
                  to contribute equally to the success of the business and the
                  culture.
                </p>
                <p>
                  Fun activities and monthly team outings all contribute and act
                  as stress buster for our people. Our employees enjoy our
                  flexibility and well balance their professional and personal
                  life. We know a happy man contributes more!
                </p>
                <p>
                  We assure our employees with a sense of certainty of a
                  successful career driven by boundless growth opportunities and
                  learning possibilities.
                </p>
                <p>
                  <strong>
                    Our work culture is focused around our people – our stars!
                  </strong>
                </p>
              </div>
            </div>
            <SidebarStaticPage isCanada phoneNumber={phoneNumber} />
          </div>
        </div>
        <TrustpilotHorizontal />
      </Layout>
    );
  }
}

export default withBg(OurCulturePage);
